import React from "react";
import Layout from "../../areas/main/components/Layout";
import NavOne from "../../areas/main/components/NavOne";
import Footer from "../../areas/main/components/Footer";
import Pricing from "./components/Pricing";
import Texts from "../../utils/lang-utils";


const Page = () => (
  <Layout pageTitle={Texts.siteTitle} language="en">
    <NavOne />
    <Pricing />
    <Footer />
  </Layout>
);

export default Page;
